<template>
  <div class=" bg min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center loginmask">
        <CCol :md="8">

          <CCard class="border-white border-solid border-1 p-4">
            <CCardBody>
                <form v-on:submit.prevent="onSubmit" novalidate>
                  <strong>Passwort vergessen!</strong>
                  <p class="">Nach Eingabe Ihrer E-Mail-Adresse erhalten sie ein neues Passwort in Ihr Postfach.</p>
                  <input-email v-model="form.username" :vld="v$.form.username"/>
                  <input-submit label="Neues Passwort per E-Mail senden" :vld="v$"/>
                </form>
            </CCardBody>
          </CCard>

        </CCol>
      </CRow>
    </CContainer>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { email, required } from '@vuelidate/validators'
import inputEmail from '@/components/form-elements/Email'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'app-password',
  components: {
    inputEmail,
    inputSubmit
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations () {
    return {
      form: {
        username: {
          required, email
        }
      }
    }
  },
  data () {
    return {
      form: {
        username: ''
      }
    }
  },
  methods: {
    onSubmit () {
      if (!this.v$.$invalid) {
        const toast = useToast()
        const payload = {
          email: this.form.username
        }
        this.axios.post('/user/password/forgotten', payload)
          .then((response) => {
            toast.success('Ein neues Passwort wurde per E-Mail versendet.')
            this.$router.push({ path: '/security/login' })
          }).catch(() => {
            toast.error('Unbekannte E-Mail-Adresse!')
          })
      }
    }
  }
}
</script>

<style lang="scss">
div.loginmask {
  div.card-body {
    padding: 0;
  }
}
</style>
